import React from 'react'

import SEO from '../components/SEO'
import Navigation from '../components/Navigation'
import ResponsiveImage from '../components/ResponsiveImage'
import Layout from '../components/Layout'

import Footer from '../components/Footer'
import Section from '../components/Section'
import Container from '../components/Container'
import Heading from '../components/Heading'
import Background from '../components/Background'
import Timeline from '../components/Timeline'
import Span from '../components/Span'


const RoadmapPage = ({ location }) => (
  <Layout>
    {/*//   <SEO title="Roadmap" />*/}
    {/*//   <Background backgroundColor="#ffffff">*/}
    {/*//   <Container>*/}
    {/*//     <Navigation as="nav" location={location} />*/}
    {/*//   </Container>*/}
    {/*//   </Background>*/}
    {/*//   <Background backgroundColor="#f5f5f6">*/}
    {/*//     <Container>*/}
    {/*//       <Section>*/}
    {/*//         <Heading align="center">Roadmap</Heading>*/}
    {/*//       </Section>*/}
    {/*//     </Container>*/}
    {/*//   </Background>*/}
    {/*//   <Container>*/}
    {/*//     <Section>*/}
    {/*//         Heeft u ideeën over de toekomstige ontwikkeling van Demodam of mis je bepaalde functionaliteit? Kijk op de <a href="https://github.com/orgs/open-zaak/projects/2">feature request backlog</a>.*/}
    {/*//     </Section>*/}
    {/*//   </Container>*/}
    {/*//   <Container>*/}
    {/*//     <Timeline>*/}
    {/*//     <Timeline.Container align="right">*/}
    {/*//         <Timeline.Content>*/}
    {/*//           <Span fontSize="0.9rem">Q3 - 2019</Span>*/}
    {/*//           <Heading as="h3" fontSize="1.5rem">Mijlpaal 1</Heading>*/}
    {/*//           <p></p>*/}
    {/*//           <ResponsiveImage src={startOntwikkeling} />*/}
    {/*//         </Timeline.Content>*/}
    {/*//       </Timeline.Container>*/}
    {/*//       <Timeline.Container align="left">*/}
    {/*//         <Timeline.Content>*/}
    {/*//           <Span fontSize="0.9rem">Q4 - 2019</Span>*/}
    {/*//           <Heading as="h3" fontSize="1.5rem">Mijlpaal 2</Heading>*/}
    {/*//           <p></p>*/}
    {/*//           <ResponsiveImage src={startInitiatief} />*/}
    {/*//         </Timeline.Content>*/}
    {/*//       </Timeline.Container>*/}
    {/*//       <Timeline.Container align="right">*/}
    {/*//         <Timeline.Content>*/}
    {/*//           <Span fontSize="0.9rem">Q1 - 2020</Span>*/}
    {/*//           <Heading as="h3" fontSize="1.5rem">Mijlpaal 3</Heading>*/}
    {/*//           <p></p>*/}
    {/*//           <ResponsiveImage src={connectingTeams} />*/}
    {/*//         </Timeline.Content>*/}
    {/*//       </Timeline.Container>*/}
    {/*//       <Timeline.Container align="left">*/}
    {/*//         <Timeline.Content>*/}
    {/*//           <Span fontSize="0.9rem">Q2 - 2020</Span>*/}
    {/*//           <Heading as="h3" fontSize="1.5rem">Mijlpaal 4</Heading>*/}
    {/*//           <p></p>*/}
    {/*//           <ResponsiveImage src={sprint} />*/}
    {/*//         </Timeline.Content>*/}
    {/*//       </Timeline.Container>*/}
    {/*//       <Timeline.Container align="right">*/}
    {/*//         <Timeline.Content>*/}
    {/*//           <Span fontSize="0.9rem">Q3 2020</Span>*/}
    {/*//           <Heading as="h3" fontSize="1.5rem">Mijlpaal 5</Heading>*/}
    {/*//           <p></p>*/}
    {/*//           <ResponsiveImage src={communityImg} />*/}
    {/*//         </Timeline.Content>*/}
    {/*//       </Timeline.Container>*/}
    {/*//       <Timeline.Container align="left">*/}
    {/*//         <Timeline.Content>*/}
    {/*//           <Span fontSize="0.9rem">Q4 -2020</Span>*/}
    {/*//           <Heading as="h3" fontSize="1.5rem">Mijlpaal 6</Heading>*/}
    {/*//           <p>Lorem ipsum.</p>*/}
    {/*//         </Timeline.Content>*/}
    {/*//       </Timeline.Container>*/}
    {/*//       <Timeline.Container align="right">*/}
    {/*//         <Timeline.Content>*/}
    {/*//           <Span fontSize="0.9rem">2021</Span>*/}
    {/*//           <Heading as="h3" fontSize="1.5rem">Mijlpaal 7</Heading>*/}
    {/*//           <p>Lorem ipsum.</p>*/}
    {/*//           <ResponsiveImage src={doorontwikkeling} />*/}
    {/*//         </Timeline.Content>*/}
    {/*//       </Timeline.Container>*/}
    {/*//     </Timeline>*/}
    {/*//   </Container>*/}
    {/*//   <Footer />*/}
  </Layout>
)

export default RoadmapPage
